<template>
  <input-text
    v-if="inputSelecionado === 'Numerico'"
    v-model="form.valorPadrao"
    class="col-12 col-md-4"
    :label="$t('modulos.campo_adicional.formulario.valorPadrao')"
    type="number"
  />
  <input-text
    v-else-if="inputSelecionado === 'Texto'"
    v-model="form.valorPadrao"
    class="col-12 col-md-4"
    :label="$t('modulos.campo_adicional.formulario.valorPadrao')"
    type="text"
  />
  <input-date
    v-else-if="inputSelecionado === 'Data'"
    v-model="form.valorPadrao"
    class="col-12 col-md-4"
    :label="$t('modulos.campo_adicional.formulario.valorPadrao')"
  />
  <input-text
    v-else-if="inputSelecionado === 'Angulo'"
    v-model="form.valorPadrao"
    v-mask="mascaraAngulo"
    class="col-12 col-md-4"
    :label="$t('modulos.campo_adicional.formulario.valorPadrao')"
    type="text"
  />
  <input-select
    v-else-if="inputSelecionado === 'Booleano'"
    v-model="form.valorPadrao"
    :options="opcoes.booleano"
    class="col-12 col-md-4"
    :label="$t('modulos.campo_adicional.formulario.valorPadrao')"
  />
  <input-combobox-text
    v-else-if="inputSelecionado === 'ListaEscolhaUnica'"
    v-model="form.valorPadrao"
    class="col-12 col-md-4"
    :options="form.itens"
    :label="$t('modulos.campo_adicional.formulario.valorPadrao')"
    :placeholder="'Valor Padrão'"
    :multiplo="false"
    :append-icon="'$downArrow'"
    somente-combo-box
    chips
  />
 
  <input-combobox-text
    v-else-if="inputSelecionado === 'ListaMultiplaEscolha'"
    v-model="form.valorPadrao"
    class="col-12 col-md-4"
    :label="$t('modulos.campo_adicional.formulario.valorPadrao')"
    :placeholder="'Valor Padrão'"
    :options="form.itens"
    :append-icon="'$downArrow'"
    multiplo
    somente-combo-box
    chips
  />
  <input-text
    v-else
    v-model="form.valorPadrao"
    class="col-12 col-md-4"
    :label="$t('modulos.campo_adicional.formulario.valorPadrao')"
    type="number"
  />
</template>
<script>
import helpers from '@/common/utils/helpers';


export default {
  props: {
    inputSelecionado: { type: String, default: "Numerico" },
    form: {}
  }, data() {
    return {
      opcoes: {
        booleano: [
        {
          text: this.$t('enums.boleano.sim'),
          value: 'true',
        },
        {
          text: this.$t('enums.boleano.nao'),
          value: 'false',
        },
      ]
      },

    }
  },
  computed:{
    mascaraAngulo(){
      return helpers.removerMascara(this.form.valorPadrao).length > 6 ? `###°##'##"` : `##°##'##"`
    }
  },
}
</script>
