<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.nome"
        class="col-12 col-md-3"
        :label="$t('modulos.campo_adicional.formulario.nome')"
        obrigatorio
        :min="1"
        :max="100"
      />
      <input-select
        v-model="form.entidade"
        class="col-12 col-md-3"
        :options="opcoes.cadastro"
        :label="$t('modulos.campo_adicional.formulario.cadastro')"
        obrigatorio
      />
      <input-select
        v-model="form.tipoCampo"
        class="col-12 col-md-3"
        :options="opcoes.tipoCampo"
        :label="$t('modulos.campo_adicional.formulario.tipoCampo')"
        obrigatorio
      />
      <input-radio
        v-model="form.obrigatorio"
        :value="form.obrigatorio"
        class="col-12 col-md-2"
        :divider="false"
        :options="opcoes.booleano"
        :label="$t('modulos.campo_adicional.formulario.campoObrigatorio')"
      />
      <input-combobox-text
        ref="input-itens"
        v-model="form.itens"
        class="col-12 col-md-8"
        :label="$t('modulos.campo_adicional.formulario.itens')"
        multiple
        chips
        :obrigatorio="campoItemListaHabilidado"
        :disabled="!campoItemListaHabilidado"
        :desabilitarVirgula="true"
      />
      <input-condicional
        v-model="form.valorPadrao"
        :input-selecionado="form.tipoCampo"
        :form="form"
      />
      <input-select
        v-model="form.edicaoValor"
        class="col-12 col-md-3"
        :options="opcoes.edicaoValor"
        :placeholder="$t('geral.inputs.selecione')"
        :label="$t('modulos.campo_adicional.formulario.edicaoValor')"
        :disabled="desabilitarCampos.edicaoValor"
      />
      <input-tipo-instrumento
        v-model="form.tiposInstrumentos"
        class="col-12 col-md-9"
        :label="$t('modulos.campo_adicional.formulario.tipoIntrumento')"
        :disabled="desabilitarCampos.tiposInstrumentos"
      />
      <input-textarea
        v-model="form.descricao"
        class="col-12"
        :label="$t('modulos.campo_adicional.formulario.descricao')"
        :obrigatorio="false"
      />
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import helpers from '@common/utils/helpers';
import InputCondicional from './componentes/InputCondicional.vue';
import CampoAdicionalService from '@common/services/cadastros/CampoAdicionalService.js';
import { CampoAdicionalModel } from '@common/models/cadastros/CampoAdicionalModel.js';
import InputTipoInstrumento from '@/components/inputs/especificos/InputTipoInstrumento';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  components: { InputTipoInstrumento, InputCondicional },
  props: ['id'],
  data() {
    return {
      valido: false,
      desabilitarCamposConfiguracao: {
        Participantes: { edicaoValor: true, tiposInstrumentos: true },
        VerificacaoIntermediaria: {
          edicaoValor: true,
          tiposInstrumentos: false,
        },
        desabilitarCampos: {
          edicaoValor: false,
          tiposInstrumentos: false,
        },
      },
      form: new CampoAdicionalModel({}),
      opcoes: {
        cadastro: [],
        tipoCampo: [],
        edicaoValor: [],
        booleano: helpers.BoleanoEnum,
      },
      primeiraRenderizacao: true,
      entidadeWatcher: null,
      tipoCampoWatcher: null,
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.campo_adicional.titulos.editar');
      return this.$t('modulos.campo_adicional.titulos.novo');
    },
    campoItemListaHabilidado() {
      return (
        this.form.tipoCampo === 'ListaMultiplaEscolha' ||
        this.form.tipoCampo === 'ListaEscolhaUnica'
      );
    },
  },

  async mounted() {
    this.buscarEntidadeEnum();
    this.buscarEdicaoValorEnum();
    this.buscarTipoCampoEnum();
    if (this.id) {
      await this.buscar(this.id);
      helpers.redirecionarSemPermissao(this, 'CampoAdicional', 'Editar');
    }
    helpers.redirecionarSemPermissao(this, 'CampoAdicional', 'Inserir');
    this.tipoCampoWatcher = this.$watch('form.tipoCampo', (tipoCampo) => {
      this.form.valorPadrao = null;
      this.form.itens = null;
      if (
        tipoCampo !== 'ListaMultiplaEscolha' &&
        tipoCampo !== 'ListaEscolhaUnica'
      )
        this.$refs.form.resetValidation();
    });
    this.entidadeWatcher = this.$watch('form.entidade', (entidade) => {
      this.desabilitarCampos(entidade);
    });

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },

  methods: {
    buscar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await CampoAdicionalService.buscar(this.id)
        .then((res) => {
          this.form = new CampoAdicionalModel(res.data);
          this.valido = true;
          this.desabilitarCampos(this.form.entidade, true);
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.campo_adicional.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      CampoAdicionalService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.campo_adicional.cadastro_sucesso`)
          );
          this.$router.push({ name: 'campo-adicional' });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'campo-adicional' });
      });
    },
    buscarEntidadeEnum: function () {
      EnumeradorService.buscar('EnumCampoAdicionalEntidade').then((res) => {
        this.opcoes.cadastro = res;
      });
    },
    buscarEdicaoValorEnum: function () {
      EnumeradorService.buscar('EnumCampoAdicionalEdicaoValor').then((res) => {
        this.opcoes.edicaoValor = res;
      });
    },
    buscarTipoCampoEnum: function () {
      EnumeradorService.buscar('EnumCampoAdicionalTipoCampo').then((res) => {
        this.opcoes.tipoCampo = res;
      });
    },
    buscarDesabilitarCamposConfiguracao: function (entidade) {
      return (
        this.desabilitarCamposConfiguracao[entidade] || {
          edicaoValor: false,
          tiposInstrumentos: false,
        }
      );
    },
    desabilitarCampos: function (entidade, ignorarCampos = false) {
      const configuracao = this.buscarDesabilitarCamposConfiguracao(entidade);
      this.desabilitarCampos.edicaoValor = configuracao.edicaoValor;
      this.desabilitarCampos.tiposInstrumentos = configuracao.tiposInstrumentos;
      if (!ignorarCampos) {
        this.form.tiposInstrumentos = [];
        this.form.edicaoValor = null;
      }
    },
  },
};
</script>
