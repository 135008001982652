import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';

export class CampoAdicionalModel {
  constructor({
    id,
    nome,
    entidade,
    tipoCampo,
    valorPadrao,
    itens = [],
    edicaoValor,
    obrigatorio = false,
    tiposInstrumentos = [],
    descricao,
  }) {
    this.id = id;
    this.nome = nome;
    this.entidade = entidade;
    this.tipoCampo = tipoCampo;
    this.valorPadrao = valorPadrao;
    if (tipoCampo == 'Numerico') {
      this.valorPadrao = Number(valorPadrao);
    }
    if (tipoCampo == 'ListaMultiplaEscolha') {
      if (valorPadrao) {
        if (valorPadrao.includes(',')) {
          this.valorPadrao = valorPadrao.split(',');
        } else {
          this.valorPadrao = [valorPadrao];
        }
      } else {
        this.valorPadrao = null;
      }
    }
    this.edicaoValor = edicaoValor;
    if (itens) this.itens = [...itens];
    this.obrigatorio = obrigatorio;
    if (tiposInstrumentos)
      this.tiposInstrumentos = new DropdownModel(tiposInstrumentos);
    this.descricao = descricao;
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.tiposInstrumentos = this.tiposInstrumentos.map((instrumento) => {
      return instrumento.value;
    });
    if (Array.isArray(this.valorPadrao))
      retorno.valorPadrao = this.valorPadrao.join(',');
    return retorno;
  }
}
